import React from 'react';
import styled from 'styled-components';
import { Title, SubTitle } from '../../ui';
import { Link } from '../../templates/sustainable-agreements';
import SustainabilityBlock from 'components/SustainabilityBlock';
const SectionWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(-2)};
  margin-bottom: 40px;
  margin-left: 10px;
   > div > div{
    border-bottom: 1.4px solid #1A1A1A26;
    font-weight: 500;
    padding-left: 13px;
  }
  > p {
    color: #6C6C6C;
  }
  > span > svg {
    height: 24px;
  }
  button {
    color: ${({ theme }) => theme.primary};
  }
`;

const Text = styled.p`
  color: #6C6C6C;
`

const Us = ({ t }) => {
  
  const learns = [
    { title: t('sustainableDevelopment.us.learn.p1.bb1'),
      text:t('sustainableDevelopment.us.learn.p1.l1')
    },

    { title: t('sustainableDevelopment.us.learn.p1.bb2'),
      text:t('sustainableDevelopment.us.learn.p1.l2')
    },

    { title: t('sustainableDevelopment.us.learn.p1.bb3'),
      text:t('sustainableDevelopment.us.learn.p1.l3')
    },

    { title: t('sustainableDevelopment.us.learn.p1.bb4'),
      text:
      (
        <>
        <Text>{ t('sustainableDevelopment.us.learn.p1.l4')}</Text> 
        <Link
        href="https://www.facebook.com/Feelingo.officiel/"
        target="_blank"
        >
        {t('modals.register.buttons.facebook')}
        </Link>
        ,{' '}
        <Link
          href="https://www.instagram.com/feelingo.officiel/"
          target="_blank"
        >
          {t('modals.register.buttons.instagram')}
        </Link>{' '}
        {t('sustainableDevelopment.us.learn.p1.l5')}{' '}
        <Link
          href="https://www.linkedin.com/company/feelingo-officiel/"
          target="_blank"
        >
          {t('modals.register.buttons.linkedIn')}
        </Link>
      </>
      )
    }
  ]
  const acts = [
    { title: t('sustainableDevelopment.us.act.bb1'),
      text:t('sustainableDevelopment.us.act.l1')
    },

    { title: t('sustainableDevelopment.us.act.bb2'),
      text:t('sustainableDevelopment.us.act.l2')
    },

    { title: t('sustainableDevelopment.us.act.bb3'),
      text:t('sustainableDevelopment.us.act.l3')
    },
  ]
  return (
    <>
      <div style={{ maxWidth: '900px' }}>
        <Title>{t('sustainableDevelopment.us.categoryTitle')}</Title>
        <p>{t('sustainableDevelopment.us.p1')}</p>
      </div>

      <div style={{ maxWidth: '820px' }}>
        <SubTitle sustainable style={{marginBottom: '40px'}}>{t('sustainableDevelopment.us.learn.title')}</SubTitle>
        <SectionWrapper>
          {(learns || []).map((result, index) => (
            <SustainabilityBlock
              key={index}
              title={result.title}
              text= {result.text}
            />
          ))}
        </SectionWrapper>
      </div>
      <div style={{ maxWidth: '820px', marginTop: '-32px' }}>
        <SubTitle sustainable style={{marginBottom: '40px', marginTop: '80px'}}>{t('sustainableDevelopment.us.act.title')}</SubTitle>
        <SectionWrapper>
        {(acts || []).map((result, index) => (
            <SustainabilityBlock
              key={index}
              title={result.title}
              text= {result.text}
            />
          ))}
        </SectionWrapper>
        <Text>{t('sustainableDevelopment.us.conclusion')}</Text>
      </div>
    </>
  );
};

export default Us;
