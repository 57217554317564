import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const SustainabilityBlock = ({ title, text }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <div onClick={() => setIsShown(!isShown)} className="cursor-pointer tw">
        <div className="flex justify-between items-center border-b-2 border-gray-100">
          <h3 className="font-medium">{title}</h3>
          <span className="text-brand-orange">
          {isShown ? (
            <MdKeyboardArrowUp size={30}/>
          ) : (
            <MdKeyboardArrowDown size={30}/>
          )}
        </span>
        </div>
      </div>

      {isShown && (
        <>
          <p>{text}</p>
        </>
      )}
    </>
  );
};

export default SustainabilityBlock;
