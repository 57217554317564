import React from 'react';
import styled from 'styled-components';
import { Title,SubTitle, TextLink as RawTextLink } from '../../ui';
import ReforestAction from '../../assets/images/reforest_action.png';
import PlasticOdysseyLogo from '../../assets/images/plastic_odyssey.png';
import PlasticOdysseyPicture from '../../assets/images/plastic_odyssey_picture.jpg';
import ReforestPicture from '../../assets/images/reforest_planting.jpg';
import { Link } from '../../templates/sustainable-agreements';
import engagement1 from '../../assets/images/NewSustanability1.svg';
import engagement2 from '../../assets/images/NewSustanability2.svg';
import engagement3 from '../../assets/images/NewSustanability3.svg';
import engagement4 from '../../assets/images/NewSustanability4.svg';
import engagement5 from '../../assets/images/NewSustanability5.svg';
import MyTabs from 'components/MyTabs';


const CategoryIcon = styled.img`
  width: auto;
  height: 28px;
  margin-right: ${({ theme }) => theme.spacing()};
`;

const TextLink = styled(RawTextLink)`
  white-space: revert;
`;

const Text = styled.p`
  color: #6C6C6C;
`

const Point = styled.span`
  &:before {
    content: '•';
    color: #67CB8B;
    width: 14px;
    display: inline-block;
  }
`

const Partners = ({ t }) => {
  const tabElements = [
    { label: t('sustainableDevelopment.partners.hotels.title') },
    { label: t('sustainableDevelopment.partners.associative.title') },
  ];

  const tabsContent = [
    <>
      <div style={{ width: '100%' }}>
        <Text>{t('sustainableDevelopment.partners.hotels.p1')}</Text>
        <Text>{t('sustainableDevelopment.partners.hotels.p2')}</Text>
        <Text>{t('sustainableDevelopment.partners.hotels.p3')}</Text>
        <Text>
          {t('sustainableDevelopment.partners.hotels.p4.l1')}{' '}
          <b>{t('sustainableDevelopment.partners.hotels.p4.b1')}</b>{' '}
          {t('sustainableDevelopment.partners.hotels.p4.l2')}{' '}
          <b>{t('sustainableDevelopment.partners.hotels.p4.b2')}</b>{' '}
          {t('sustainableDevelopment.partners.hotels.p4.l3')}{' '}
          <b>{t('sustainableDevelopment.partners.hotels.p4.b3')}</b>
        </Text>
        <Text>{t('sustainableDevelopment.partners.hotels.p5')}</Text>
      </div>
      <div>
        <SubTitle sustainable>
          <CategoryIcon src={engagement1} />
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.preTitle',
              {
                hotelType: t(
                  'sustainableDevelopment.partners.hotels.sustanabilityLevel.1.type',
                ),
              },
            )}
          </SubTitle>
          <Text>
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.1.descriptions.1',
            )}
            <TextLink href="https://storage.googleapis.com/koob-prod/Liens%20PDF%20sur%20feelingo.com/CHARTE%20ENGAGEMENT%20DURABLE%20DES%20FEELINGOOD'HOME.pdf">
              {t(
                'sustainableDevelopment.partners.hotels.sustanabilityLevel.1.descriptions.2',
              )}
            </TextLink>
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.1.descriptions.3',
            )}
          </Text>
          <SubTitle sustainable>
            <CategoryIcon src={engagement2} />
              {t(
                'sustainableDevelopment.partners.hotels.sustanabilityLevel.preTitle',
                {
                  hotelType: t(
                    'sustainableDevelopment.partners.hotels.sustanabilityLevel.2.type',
                  ),
                },
              )}
          </SubTitle>
          <Text>
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.2.description',
            )}
          </Text>
          <SubTitle sustainable>
            <CategoryIcon src={engagement3} />
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.preTitle',
              {
                hotelType: t(
                  'sustainableDevelopment.partners.hotels.sustanabilityLevel.3.type',
                ),
              },
            )}
          </SubTitle>
          <Text>
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.3.description',
            )}
          </Text>
          <SubTitle sustainable>
          <CategoryIcon src={engagement4} />
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.preTitle',
              {
                hotelType: t(
                  'sustainableDevelopment.partners.hotels.sustanabilityLevel.4.type',
                ),
              },
            )}
          </SubTitle>
          <Text>
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.4.description',
            )}
          </Text>
          <SubTitle sustainable>
            <CategoryIcon src={engagement5} />
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.preTitle',
              {
                hotelType: t(
                  'sustainableDevelopment.partners.hotels.sustanabilityLevel.5.type',
                ),
              },
            )}
          </SubTitle>
          <Text>
            {t(
              'sustainableDevelopment.partners.hotels.sustanabilityLevel.5.description',
            )}
          </Text>
      </div>

    </>,
    <>
      <div>
        <img
          src={ReforestAction}
          alt=""
          style={{ maxWidth: '130px', maxHeight: '70px', marginRight: '32px' }}
        />
        <SubTitle sustainable>
          {t('sustainableDevelopment.partners.associative.reforest.title')}
        </SubTitle>
        <Text>{t('sustainableDevelopment.partners.associative.reforest.p1')}</Text>
        <Text>
          <Point>{t('sustainableDevelopment.partners.associative.reforest.p2.ll1')}{' '}</Point> 
          <br />
          <Point>{t('sustainableDevelopment.partners.associative.reforest.p2.ll2')}</Point> 
        </Text>
        <Text>{t('sustainableDevelopment.partners.associative.reforest.p3')}</Text>
        <img
          src={ReforestPicture}
          alt=""
          style={{ height: '300px', width: '100%', objectFit: 'cover', borderRadius: '30px'}}
        />
        <Text>{t('sustainableDevelopment.partners.associative.reforest.p4')}</Text>
        <Text>
        <Point> {t('sustainableDevelopment.partners.associative.reforest.p5.ll1')}</Point> 
          <br />
          <Point> {t('sustainableDevelopment.partners.associative.reforest.p5.ll2')}</Point> 
        </Text>
        <Text><Point> {t('sustainableDevelopment.partners.associative.reforest.pp6')}</Point> </Text>
        <Text><Point>  {t('sustainableDevelopment.partners.associative.reforest.pp7')}</Point> </Text>
        <Text>
          {t('sustainableDevelopment.partners.associative.reforest.p8')} <br />{' '}
          <Link href="https://www.reforestaction.com/ " target="_blank">
            https://www.reforestaction.com/{' '}
          </Link>
        </Text>
      </div>
      <div >
        <img
          src={PlasticOdysseyLogo}
          alt=""
          style={{
            maxWidth: '130px',
            maxHeight: '92px',
            marginRight: '32px',
            marginTop: '22px',
          }}
        />
        <SubTitle sustainable>
          {t('sustainableDevelopment.partners.associative.plastic.title')}
        </SubTitle>
        <Text>{t('sustainableDevelopment.partners.associative.plastic.p1')}</Text>
        <Text>{t('sustainableDevelopment.partners.associative.plastic.p2')}</Text>
        <img
          src={PlasticOdysseyPicture}
          alt=""
          style={{ height: '300px', width: '100%', objectFit: 'cover', borderRadius: '30px' }}
        />
        <Text>{t('sustainableDevelopment.partners.associative.plastic.p3')}</Text>
        <Text>{t('sustainableDevelopment.partners.associative.plastic.p4')}</Text>
        <Text>
          {t('sustainableDevelopment.partners.associative.plastic.p5')}
          <br />
          <Link href="https://plasticodyssey.org/" target="_blank">
            https://plasticodyssey.org/{' '}
          </Link>
        </Text>
      </div>
    </>,
  ];
  return (
    <>
    <Title>{t('sustainableDevelopment.partners.categoryTitle')}</Title>
    <MyTabs
      isSticky={false}
      isVertical={true}
      tabs={tabElements}
      contents={tabsContent}
      variant="search"
      sustainable = {true}
    />
    </>
  );
};

export default Partners;
