import React, { useState } from 'react';
import { CertificationCard } from 'ui/cards';
import { GridContainer } from 'ui/containers';
import CertificationModal from 'components/CertificationModal';

const Certification = ({ certifications }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentShow, setContentShow] = useState();

  const onClose = () => {
    setIsOpen(false);
  }

  return (
    <>
      <GridContainer variant="certification">
        {(certifications || []).map(
            (certification, i) => (
              <>
                {certification?.display === true && (
                  <CertificationCard {...certification} index= {i} setContentShow= {setContentShow} setIsOpen= {setIsOpen}/>
                )}
              </>
            ),
          )
        }
        
      </GridContainer>
      {contentShow || contentShow === 0  ? <CertificationModal certification={certifications[contentShow]} isOpen={isOpen} onClose={onClose} /> : '' }
    </>
  );
};

export default Certification;
