import React from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Content from 'components/Content';
import { get } from 'lodash';
import { CertificationTitle, CertificationPictureBackground, CertificationPicture, CertificationContainer } from 'ui/cards';


const CertificationModal = ({certification, isOpen, onClose}) => {
  const imageUrl = get(certification.pictogram, 'url', '');

  const { t } = useTranslation();

  const Block = styled.div`
  position: relative;
  display: block;
  width: 94%;
  margin-left: 3%;
  border-radius: 4px;

  padding: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing()};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const CertificationContent = styled.div`
  > div{
    width: 85%;
    margin-left: 7%;
    > p {
       font-size: 1rem;
     }
  }
`
const Divider = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 20px;
`;

const Title = styled.div`
  padding-bottom: 15px;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
`;

  return (
      <Modal isOpen={isOpen} onRequestClose={onClose} scrollable>
        <Block>
          <Title>{t('sustainableDevelopment.certifications.titleModal')}</Title>
          <Divider />
          <CertificationContainer modal>
            <CertificationPictureBackground modal>
              <CertificationPicture imageUrl={imageUrl}/>
            </CertificationPictureBackground>
            <CertificationTitle modal>{certification.displayName}</CertificationTitle>
            <CertificationContent>
             <Content html={certification.content} />
            </CertificationContent>
          </CertificationContainer>
        </Block>
      </Modal>
  );
};

export default CertificationModal;
