import React from 'react';
import styled from 'styled-components';
import { Title, SubTitle,  Button } from '../../ui';
import { Link } from '../../templates/sustainable-agreements';

const Text = styled.p`
  color: #6C6C6C;
`

const Commitment = ({ t, changeCategory }) => {

  const ScrollToTop = () => {
    const  windowWidth = window.innerWidth;
    var marginTop = 600 
     if (windowWidth < 750) {
       marginTop = 350
     }
     window.scrollTo({
       top: marginTop,
       behavior: 'smooth',
     });
   }
return (
    <>
    <div>
      <Title sustainable>{t('sustainableDevelopment.commitment.title')}</Title>
      <Text>{t('sustainableDevelopment.commitment.p1.l1')} </Text>
      <b>{t('sustainableDevelopment.commitment.p1.b1')}</b>

      <Text>{t('sustainableDevelopment.commitment.p1.l2')}</Text>
    </div>
    <div style={{ marginTop: '48px' }}>
      <Title sustainable>
        {t('sustainableDevelopment.commitment.categoryTitle')}
      </Title >
      <Text>{t('sustainableDevelopment.commitment.p2.subtitle')}</Text>
     
      <SubTitle sustainable> {t('sustainableDevelopment.commitment.p2.b1')}</SubTitle>
      <Text>
        {t('sustainableDevelopment.commitment.p2.l1')}
      </Text>

      <SubTitle sustainable> {t('sustainableDevelopment.commitment.p2.b2')}</SubTitle>{' '}
      <Text sustainable>
        {t('sustainableDevelopment.commitment.p2.l2')}{' '}
        <Link
          href="https://storage.googleapis.com/koob-prod/Liens%20PDF%20sur%20feelingo.com/Politique_D%C3%A9veloppement_Durable_FEELINGO.pdf"
          target="_blank"
        >
          {t('sustainableDevelopment.commitment.p2.a1')}
        </Link>{' '}
        {t('sustainableDevelopment.commitment.p2.l3')}
      </Text>
      
    </div>

    <div>
    <SubTitle sustainable> {t('sustainableDevelopment.commitment.p3.b1')} {' '}  {t('sustainableDevelopment.commitment.p3.a1')} {' '} {t('sustainableDevelopment.commitment.p3.b2')}</SubTitle>
      <Text>
        {t('sustainableDevelopment.commitment.p3.l1')}
      </Text>{' '}
      <Button
        variant="carnet"
        sustainablebtn
        onClick={() => {
          changeCategory(2);
          ScrollToTop();
        }}
        
      >
        {t('sustainableDevelopment.commitment.our_certifications')}
      </Button>
    </div>
    <SubTitle sustainable> {t('sustainableDevelopment.commitment.p4.b1')} {' '}  {t('sustainableDevelopment.commitment.p4.a1')}{' '} {t('sustainableDevelopment.commitment.p4.b2')}.</SubTitle>
      <Text>
        {t('sustainableDevelopment.commitment.p4.l1')}
      </Text>
      <Button
        variant="carnet"
        sustainablebtn
        onClick={() => {
          changeCategory(1);
          ScrollToTop();
        }}
      >
        {t('sustainableDevelopment.commitment.our_partners')}
      </Button>
    
  </>
);
};

export default Commitment;
