import { get } from './';

export const getGeneralInformations = () => get('/api/v1/general_information');

export const getEngagementBlocks = (language) => get('/api/v1/engagement_blocks', { lang: language });

export const getAccreditations = ({ language }) =>
  get('/api/v1/koob/accreditations', {
    lang: language,
    options: { includes: ['pictogram'] },
  });
