import React , { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PendingBCorp from '../../assets/svg/Certified_B_Corporation.svg';
import ChildSafeMovement from '../../assets/svg/child_safe_movement.svg';
import ChildSafeTraveler from '../../assets/images/childSafe_traveler.jpg';
import { Link } from '../../templates/sustainable-agreements';
import Certification from '../Certification';
import MyTabs from 'components/MyTabs';
import Spinner from 'components/Spinner';
import { SpinnerContainer, Title, SubTitle, media } from 'ui';
import { toLowerWithoutAccents } from '../../utils';

const FeelingoCertification = styled.div`
  width: 100%;
  > input {
    ${media.mobile`
      width: 100% !important;
    `}
  }
`;
const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color : #F7F7F7;
  border-radius: 30%;
  width: 100px;
  height: 110px;
  margin-top: 50px;
  margin-bottom: -20px;
`

const Img = styled.img`
  max-width: 150px;
  ${props =>
    props.small &&
    css`
      width: 70px;
      height: 70px;
      transition: all 0.3s linear 0s;
      object-fit: contain;
  `}
  ${props =>
    props.large &&
    css`
      max-width: 100%;
    `}
`;


const Text = styled.p`
  color: #6C6C6C;
`
const Point = styled.span`
  &:before {
    content: '•';
    color: #67CB8B;
    width: 14px;
    display: inline-block;
  }
`
const Certifications = ({ t, certifications }) => {

  const [filterCertifications, setFilterCertifications] = useState([]);
  const [search, setSearch] = useState(" ");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if(certifications){
      setFilterCertifications(() => certifications);
      setIsLoading(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  const filterCertification = (certifs) => {
    setFilterCertifications( () => certifs.filter((certification) =>
      certification.displayName ? 
        certification.displayName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.toLowerCase())
      :  ''
    ))
  }

  const SortCertifications = (certifs) => {
   return certifs.sort( (a, b) => a.displayName?.localeCompare(b.displayName, 'en', {'sensitivity': 'base'}));
  }


  useEffect(() => {
    if(filterCertifications.length === 0){
      filterCertification(certifications)
    }
    if (search.length  && filterCertifications.length) {
      filterCertification(filterCertifications)
    }else if(search.length === 0){
      setFilterCertifications( () => certifications)
    }  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const tabElements = [
    { label: t('sustainableDevelopment.certifications.feelingo.title') },
    { label: t('sustainableDevelopment.certifications.hotels.title') },
  ];
  
  const tabsContent = [
    <>
      <FeelingoCertification>
          <ImgContainer>
            <Img src={PendingBCorp} alt="Pending B Corp" small />
          </ImgContainer>
          <SubTitle sustainable>
            {t('sustainableDevelopment.certifications.feelingo.bCorp.title')}
          </SubTitle>
          <Text>{t('sustainableDevelopment.certifications.feelingo.bCorp.p1')}</Text>
          <Text>{t('sustainableDevelopment.certifications.feelingo.bCorp.p2')}</Text>
          <Text><Point>{t('sustainableDevelopment.certifications.feelingo.bCorp.pp3')}</Point></Text>
          <Text><Point>{t('sustainableDevelopment.certifications.feelingo.bCorp.pp4')}</Point></Text>
          <Text><Point>{t('sustainableDevelopment.certifications.feelingo.bCorp.pp5')}</Point></Text>
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.bCorp.p6')}
            <br />
            <Link href="https://bcorporation.net/ " target="_blank">
              https://bcorporation.net/{' '}
            </Link>
          </Text>
      </FeelingoCertification>
      <FeelingoCertification>
          <ImgContainer>
          <Img
            src={ChildSafeMovement}
            alt="ChildSafe movement"
            style={{ marginBottom: '16px' }}
            small
          />
          </ImgContainer>
          <SubTitle sustainable>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.title')}
          </SubTitle>
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.p1')}
          </Text>
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.p2')}
          </Text>
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.p3')}
          </Text>
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.p4')}
          </Text>
          <Text>
              {t('sustainableDevelopment.certifications.feelingo.childSafe.p5')}
          </Text>
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.p6')}
          </Text>
          <Img large src={ChildSafeTraveler} alt="ChildSafe Traveler" 
              style={{ height: '300px', width: '100%', objectFit: 'cover', borderRadius: '30px'}}
          />
          <Text>
            {t('sustainableDevelopment.certifications.feelingo.childSafe.p7')}
            <Link href="https://thinkchildsafe.org/" target="_blank">
              https://thinkchildsafe.org/
            </Link>
          </Text>
      </FeelingoCertification>
    </>,
    <>
      <FeelingoCertification>
          <Text>{t('sustainableDevelopment.certifications.hotels.p1')}</Text>
            <input
              className={`form-input block px-6 mb-6 mt-10 border-b-2 border-gray-100 focus:border-brand-orange focus:ring-0 "`}
              style= {{width: '48%', borderRadius: '15px', border: '1px solid #E5E0E0'}}
              type="text"
              placeholder={t('sustainableDevelopment.certifications.searchPlaceholder')}
              onChange={(e) => setSearch(toLowerWithoutAccents(e.target.value))}
            />
            {isLoading ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <Certification certifications={SortCertifications(filterCertifications)} /> 
            )}
      </FeelingoCertification>
    </>,
  ];

  return(
    <>
      <Title>{t('sustainableDevelopment.certifications.categoryTitle')}</Title>
      <MyTabs
        isSticky={false}
        isVertical={true}
        tabs={tabElements}
        contents={tabsContent}
        variant="search"
        sustainable = {true}
      />  
   </>
  );
};

export default Certifications;
