import React, { useContext, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Layout from '../components/Layout';
import { Container } from '../ui/containers';
import { media,  Button as RawButton } from '../ui';
import backgroundNotreDemarcheDurable from '../assets/images/notre_demarche_durable.png';
import { useTranslation } from 'react-i18next';
import Travelogue from '../components/Travelogue';
import Commitment from '../components/SustainableAgreements/Commitment';
import Partners from '../components/SustainableAgreements/Partners';
import Certifications from '../components/SustainableAgreements/Certifications';
import Us from '../components/SustainableAgreements/Us';
import { getAccreditations } from '../api/general';
import { useAsync } from 'react-async';
import PageContext from 'contexts/page';
import SEO from '../components/seo';
import HeroHeader from '../components/HeroHeader';
import { Select } from '../components/Select';
import {
  LeftTabContainer,
  RightTabContainer,
  SplitTabContainer,
} from 'ui/tabs';

export const Link = styled.a`
  color: ${({ theme }) => theme.secondary};

  ${({ isInternal }) =>
    isInternal &&
    `
    cursor: pointer;
    text-decoration: underline;
  `}
`;

export const Button = styled(RawButton)`
  white-space: pre-line;
  text-align: left;
  margin-bottom: 6px !important;
  margin-left: 15px;
  text-transform: none;
  color: #6C6C6C;
  padding-top: 10px;
  font-size: 16px;
  ${props =>
    props.isActive &&
    css`
      color: #000 !important;
      font-weight: bold;
  `}
`;

export const TabContent = styled.div`
`

export const MobileMenuContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: none;
  backgound-color: white;
  margin-top: -30px !important;
  ${props =>
    props.isDestination &&
    css`
     top: 0 !important;
     margin-top:  0px !important;
  `}
  padding: 10px;
  ${Button} {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    margin-right: ${({ theme }) => theme.spacing()};
  }
  
  ${media.tablet`
    max-width: 300px;
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  `}

  ${media.mobile`
    display: flex;
    justify-content: center;
  `}
`

export const TabItem = styled.div`
  width: 90%;
  cursor: pointer;
  color: #F7F7F7;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: left;
  white-space: pre-line;
  text-align: left;
  padding: 6px;
  margin-bottom: 15px;
  height: 23px;
  z-index: 1000;
  ${props =>
    props.isActive &&
    css`
      color: #000 !important;
      font-weight: bold;
      border-left: 2px solid #67CB8B;
  `}
`
const TravelogueContainer = styled.div`
    position: relative;
    z-index: 0 !important;
    background-color: #F7F7F7;
    ${media.mobile`
      z-index: 1 !important;
  `}
`
export const query = graphql`
  query($language: String!) {
    allBlog(limit: 3, filter: { language: { eq: $language } }) {
      nodes {
        id
        slug
        displayName
        publishedAt
        excerpt
        picture {
          imagePath
        }
        thematic {
          displayName
        }
      }
    }
  }
`;

const SustainableAgreements = ({
  data: {
    allBlog: { nodes: articles },
  }
}) => {
  const { language } = useContext(PageContext);
  const { t } = useTranslation('')
  const { data: certifications } = useAsync({
    promiseFn: getAccreditations,
    language,
  });
  const [contentSelected, setContentSelected] = useState(0);
  const [stickyClass, setStickyClass] = useState('');

  const handleClick = (index) => {
    setContentSelected(index)
  };

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50 ? setStickyClass('sticky-nav-sustainable') : setStickyClass('');
    }
  };

  const ScrollToTop = () => {
   const  windowWidth = window.innerWidth;
   var marginTop = 600 
    if (windowWidth < 750) {
      marginTop = 350
    }
    window.scrollTo({
      top: marginTop,
      behavior: 'smooth',
    });
  }

  const categories = [
    {
      slug: t('sustainableDevelopment.commitment.slug'),
      name: t('sustainableDevelopment.commitment.categoryTitle'),
      content: (
        <Commitment t={t} changeCategory={handleClick} />
      ),
    },
    {
      slug: t('sustainableDevelopment.partners.slug'),
      name: t('sustainableDevelopment.partners.categoryTitle'),
      content: (
        <Partners t={t} changeCategory={handleClick} />
      ),
    },
    {
      slug: t('sustainableDevelopment.certifications.slug'),
      name: t('sustainableDevelopment.certifications.categoryTitle'),
      content: <Certifications t={t} certifications={certifications} />,
    },
    {
      slug: t('sustainableDevelopment.us.slug'),
      name: t('sustainableDevelopment.us.categoryTitle'),
      content: <Us t={t} />,
    },
  ];

  return (
    <>
      <Layout>
        <SEO title={categories[contentSelected]?.name} />
        <MobileMenuContainer className={stickyClass}>
          <Select
            name="menu"
            onChange={input => {
              handleClick(input.value, 'menu');
              ScrollToTop();
            }}
            options={[
              ...(categories || []).map((result, index) => ({
                label: result.name,
                value: index,
              })),
            ]}
            value={contentSelected}
            placeholder={categories[contentSelected].name}
            variant={'menu'}
            type={'article'}
          />
        </MobileMenuContainer>
        <HeroHeader
          title={t('sustainableDevelopment.title1')}
          subtitle={t('sustainableDevelopment.title2')}
          image={backgroundNotreDemarcheDurable}
          sustainable
        />
        <Container>
          <SplitTabContainer menuContainer sustainableContainer>
            
              <LeftTabContainer sideMenuSustainable>
                {categories.map((tab, index) => (
                  <TabItem isActive={contentSelected === index} 
                    onClick={e => {
                      e.preventDefault();
                      ScrollToTop();
                      setContentSelected(index);
                    }}
                  >
                    <Button
                      variant="tab"
                      isActive={contentSelected === index}
                      onClick={e => {
                        e.preventDefault();
                        ScrollToTop();
                        setContentSelected(index);
                      }}
                    >
                      {tab.name}
                    </Button>
                  </TabItem>
                ))}
              </LeftTabContainer>
              <RightTabContainer menuContent>
                <TabContent style={{ outline: 'none', marginTop: 20 }}>
                  {categories[contentSelected].content}
                </TabContent>
              </RightTabContainer>
          </SplitTabContainer>
        </Container>
          <TravelogueContainer>
             <Travelogue articles={articles}/>
          </TravelogueContainer>
      </Layout>
    </>
  );
};

export default SustainableAgreements;
