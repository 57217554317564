import styled, { css } from 'styled-components';
import { media, Button } from './';

export const LeftTabContainer = styled.div`
  width: 100%;
  max-width: 100%;
  ${Button} {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    margin-right: ${({ theme }) => theme.spacing()};
  }
  ${media.tablet`
    max-width: 300px;
    margin-top: ${({ theme }) => theme.spacing(0.5)};

  `};
  ${props =>
    props.sideMenuSustainable &&
    css`
    max-height: 440px;
    display: block;
    position:fixed;
    top:210px;
    bottom:0%;
    z-index: 0;
    ${media.mobile`
      display:none;
    `}
  `}

  ${props =>
    props.sideMenu &&
    css`
    box-shadow: 0 3px 26px #0000000A;
    border: 1px solid #F5F5F5;
    border-radius: 25px;
    display: block;
    position: fixed;
    top: 150px;
    ${media.mobile`
      display:none;
    `}
  `}
`;

export const RightTabContainer = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: left;

  > * + * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  ${props =>
    props.menuContent &&
    css`
    ${media.desktop`
      margin-left: 25%;
    `}
    ${media.tablet`
      margin-left: 38%;
    `}
  `}

  ${media.tablet`
    text-align: left;

    ${Button} {
      display: flex;
      justify-content: flex-end;
    }
  `}
`;

export const SplitTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props =>
    props.menuContainer &&
    css`
    ${media.desktop`
      min-height: 900px;
    `}
    ${media.tablet`
      min-height: 800px;
    `}
  `}
  ${props =>
    props.destination &&
    css`
    ${media.desktop`
      min-height: 0px;
    `}
    ${media.tablet`
      min-height: 0px;
    `}
  `}
  ${media.tablet`
    position: relative;
    flex-direction: row;
  `}
  ${props =>
    props.sustainableContainer &&
    css`
      z-index: 0;
  `}
`;
